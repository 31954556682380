import React from "react";
import useSoilTestingViewModel from "./soiltestingViewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "./allRequestsScreen.css";

const cropOptions = [
  "Dhan",
  "Gehu",
  "Makai",
  "Arhar",
  "Urad",
  "Chana",
  "Sarso",
  "Aloo",
  "Pyaaz",
  "Tamatar",
  "PhoolGobi",
  "Matar",
  "ShimlaMirch",
  "Mirch",
  "Gajar",
  "Chukandar",
  "Lahsun",
  "Adrak",
  "Haldi",
  "Papaya",
  "Banana",
  "Orange",
  "Mango",
  "Sweetlime",
  "Gooseberry",
];

const AllRequestsScreen = () => {
  const {
    allRequestsList,
    isOpen,
    toggleModel,
    getAllRequests,
    isLoading,
    createNewRequests,
    isUpdateModalOpen,
    handleSubmit,
    handleUpdate,
    handleCloseModal,
    handleSave,
    selectedRequest,
    setSelectedRequest,
    centers,
    totalPages,
    currentPage,
    formData,
    sortOrder,
    searchTerm,
    handleSearchChange,
    handleFormChange,
    handleFormSubmit,
    handlePageChange,
    selectedFieldUnit,
    handleFieldUnit,
  } = useSoilTestingViewModel(); // Call the hook here

  const filteredData = allRequestsList.filter((row) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (sortOrder === "PENDING") {
    filteredData.sort((a, b) => (a.status === "PENDING" ? -1 : 1));
  } else if (sortOrder === "In Progress") {
    filteredData.sort((a, b) => (a.status === "In Progress" ? -1 : 1));
  } else if (sortOrder === "Completed") {
    filteredData.sort((a, b) => (a.status === "Completed" ? -1 : 1));
  }
  const irrigationOptions = [
    "बोरवेल",
    "टपक सिंचाई",
    "वर्षा",
    "कूआ",
    "नहर",
    "नदी",
    "तालाब",
    "बारसाती नाला",
  ];
  const soilTypes = [
    "लाल",
    "काली",
    "धोमट", // Updated from "वाट"
    "चिकनी मिट्टी",
    "अन्य",
  ];

  return (
    <div className="container">
      <div className="header">
        <h1>मिट्टी परीक्षण</h1>
        <button className="new-request-button" onClick={toggleModel}>
          नया अनुरोध
        </button>
      </div>
      <div className="controls">
        <input
          type="text"
          className="search-bar"
          placeholder="Search by Name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {isLoading ? (
        <div className="loading-spinner">लोड हो रहा है...</div>
      ) : allRequestsList.length === 0 ? (
        <div>कोई अनुरोध नहीं मिला!</div>
      ) : (
        <div>
          <table className="data-table">
            <thead>
              <tr>
                <th>परीक्षण आईडी</th>
                <th>नाम</th>
                <th>गाँव</th>
                <th> खंड</th>
                <th>जिला</th>
                <th>राज्य</th>
                <th>प्लॉट नंबर</th>
                <th>मोबाइल</th>
                <th>क्षेत्रफल</th>
                <th>मिट्टी का प्रकार</th>
                <th>सिंचाई का तरीका</th>
                <th>फसल का नाम</th>
                <th>सैंपलिंग तिथि</th>
                <th>परीक्षण तिथि</th>
                <th>स्थिति</th>
                <th>अद्यतन करें</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={row.id}>
                  <td>{row?.testId}</td>
                  <td>{row?.name}</td>
                  <td>{row?.village}</td>
                  <td>{row?.block ?? NAN}</td>
                  <td>{row?.district}</td>
                  <td>{row?.state}</td>
                  <td>{row?.plotNo}</td>
                  <td>{row?.phoneNumber}</td>
                  <td>{row?.fieldSize}</td>
                  <td>{row?.soilType}</td>
                  <td>{row?.irrigationMode}</td>
                  <td>{row?.cropName}</td>
                  <td>
                    {new Date(row.sampleDate).toLocaleDateString("en-US")}
                  </td>
                  <td>
                    {new Date(row.testingDate).toLocaleDateString("en-US")}
                  </td>
                  <td>
                    <span className={`status ${row.status.toLowerCase()}`}>
                      {row.status}
                    </span>
                  </td>
                  <td>
                    <button
                      className="update-button"
                      disabled={row.status !== "PENDING"}
                      onClick={() => handleUpdate(row)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              पिछला
            </button>
            <span>
              {currentPage} में से {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              अगला
            </button>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModel}>
              &times;
            </span>
            <form onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                Village:
                <input
                  type="text"
                  name="village"
                  value={formData.village}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                Block:
                <input
                  type="text"
                  name="block"
                  value={formData.block}
                  onChange={handleFormChange}
                />
              </label>
              <label>
                District:
                <input
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleFormChange}
                  required
                />
              </label>

              <label>
                Mobile:
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                State:
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                Plot No:
                <input
                  type="text"
                  name="plotNo"
                  value={formData.plotNo}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                Khasra Number:
                <input
                  type="text"
                  name="fieldKharaNumber"
                  value={formData.fieldKharaNumber}
                  onChange={handleFormChange}
                />
              </label>
              <label>
                Field Coordinates:
                <input
                  type="text"
                  name="fieldCoordinates"
                  value={formData.fieldCoordinates}
                  onChange={handleFormChange}
                />
              </label>
              <label>
                Field Size: ( in Acre )
                <select
                  value={selectedFieldUnit}
                  onChange={(event) => {
                    handleFieldUnit(event.target.value);
                  }}
                >
                  <option value="एकड़">Acre</option>
                  <option value="हेक्टेयर">Hectare</option>
                  <option value="कोरिम्बिया">Corymbia</option>
                  <option value="बीघा">Bigaha</option>
                  <option value="बिश्वा">Bishwa</option>
                </select>
                <input
                  type="number"
                  name="fieldSize"
                  value={formData.fieldSize}
                  onChange={handleFormChange}
                  onWheel={(e) => e.target.blur()}
                  required
                />
              </label>
              <label>
                Soil Type:
                <select
                  name="soilType"
                  value={formData.soilType}
                  onChange={handleFormChange}
                  required
                >
                  <option value="">--Select Soil Type--</option>
                  {soilTypes.map((soilType, index) => (
                    <option key={index} value={soilType}>
                      {soilType}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Irrigation Mode:
                <select
                  name="irrigationMode"
                  value={formData.irrigationMode}
                  onChange={handleFormChange}
                  required
                >
                  <option value="">Select</option>
                  {irrigationOptions.map((mode, index) => (
                    <option key={index} value={mode}>
                      {mode}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Crop Name:
                <select
                  name="cropName"
                  value={formData.cropName}
                  onChange={handleFormChange}
                  required
                >
                  <option value="" disabled>
                    Select Crop
                  </option>
                  {cropOptions.map((crop, index) => (
                    <option key={index} value={crop}>
                      {crop}
                    </option>
                  ))}
                </select>
              </label>
              {/* <label>
                Centres
                <select
                  name="centre"
                  value={formData.centre}
                  onChange={handleFormChange}
                  required
                >
                  <option value="" disabled>
                    Select Centre
                  </option>
                  {centers.map((centre, index) => (
                    <option key={index} value={centre?._id}>
                      {centre?.name}
                    </option>
                  ))}
                </select>
              </label> */}
              <label>
                Sampling Date:
                <input
                  type="date"
                  name="samplingDate"
                  value={formData.samplingDate}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <div className="form-buttons">
                <button type="submit">Submit</button>
                <button type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isUpdateModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <form onSubmit={handleSubmit} className="scrollable-form">
              {[
                "Nitrogen",
                "Phosphorus",
                "Potassium",
                "OrganicCarbon",
                "Conductivity",
                "pH",
                "Sulfur",
                "Copper",
                "Zinc",
                "Manganese",
                "Iron",
                "Boron",
                "Urea",
                "DAP",
                "MOP",
                "ZincSulfate",
                "Gypsum",
                "Borax",
                "FerrousSulfate",
                "ManganeseSulfate",
                "CopperSulfate",
                "Lime",
                "CalciumSulfate",
              ].map((element, idx) => (
                <label key={idx} className="form-element">
                  {element}:
                  <span style={{ color: "red" }}>
                    {[
                      "OrganicCarbon",
                      "Conductivity",
                      "pH",
                      "Nitrogen",
                      "Phosphorus",
                      "Potassium",
                    ].includes(element)
                      ? "*"
                      : ""}
                  </span>
                  <input
                    type="number"
                    name={element}
                    onWheel={(e) => e.target.blur()}
                    value={selectedRequest?.[element]}
                    onChange={(e) =>
                      setSelectedRequest((prev) => {
                        return {
                          ...prev,
                          [element]: parseFloat(e.target.value),
                        };
                      })
                    }
                  />
                </label>
              ))}
              <div className="form-buttons">
                <button type="button" onClick={handleSave}>
                  {" "}
                  Save
                </button>
                <button type="submit">Submit</button>
                <button type="button" onClick={handleCloseModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllRequestsScreen;
