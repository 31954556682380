import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SoilTestingRepository } from "../../repository/soilTesting.repository";
import { useAlert } from "react-alert";
export const useSoilReportManager = () => {
  const { id } = useParams();
  const [reportData, setReportData] = useState({});
  const alert = useAlert();
  const reportRepository = new SoilTestingRepository();
  useEffect(() => {
    const getReport = async () => {
      try {
        if (id) {
          const data = await reportRepository.getSoilReport(id);
          setReportData(data);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
    getReport();
  }, []);
  return { reportData };
};
