import { useContext } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { appColors } from "../../res/colors";
import Component from "../../components";
import "./index.css";
import { NavIcons } from "../../res/assets";
import NavItem from "./components";
import { AuthContext } from "../../context/auth.context";
const menuItemStyles = {
  padding: 10,
  "&:hover": {
    backgroundColor: "#f8f8f8we",
    color: "black",
  },
};
const mainStyles = {
  backgroundColor: appColors.lightColor,
  overflow: "hidden",
};
const deviceWidth = window.innerWidth;

export default function SideBar() {
  const { toggleSidebar, broken } = useProSidebar();
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem("user"));
  const user = {
    roles: "admin",
  };

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    navigate("/");
  };

  return (
    <div className="sidebarContainer">
      <Sidebar breakPoint="lg" backgroundColor="#fff">
        <div className="headerContainer">
          <div>
            <div className="logoSidebar">
              <NavIcons.LogoBig />
            </div>
            <Menu>
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/home"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Home"
                  />
                }
              />
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/updateRequests"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Update Requests"
                  />
                }
              />
            </Menu>
          </div>
          <div className="logOutButtonContainer">
            <Component.Button onClick={handleLogout} title="Logout">
              Logout
            </Component.Button>
          </div>
        </div>
      </Sidebar>
      <main
        style={{
          width: broken ? deviceWidth : deviceWidth - 250,
          ...mainStyles,
        }}
      >
        {broken && (
          <div className="hamburgerIcon">
            <button className="sb-button" onClick={() => toggleSidebar()}>
              <NavIcons.HamburgerIcon />
            </button>
            <div className="topLogo">
              <NavIcons.LogoBig />
            </div>
          </div>
        )}
        <Outlet context={broken} />
      </main>
    </div>
  );
}
