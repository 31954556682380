import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class SoilTestingRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async getAllRequests(page) {
    try {
      const url = `${this.appUrl.requests}?page=${page}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  async createNewRequest(body) {
    try {
      const url = this.appUrl.requests;
      return this.apiService.getPostApiResponse(url, body);
    } catch (error) {
      throw error;
    }
  }

  async createSoilData(body) {
    try {
      const url = this.appUrl.soil;
      return this.apiService.getPostApiResponse(url, body);
    } catch (error) {
      throw error;
    }
  }

  async getSoilData(id) {
    try {
      const url = `${this.appUrl.soil}?page=${id}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async getSoilReport(id) {
    const url = `${this.appUrl.soil}/${id}`;
    return this.apiService.getGetApiResponse(url);
  }

  async getAllSoilData(id) {
    try {
      const url = `${this.appUrl.soil}?page=${id}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async saveSoilData(body) {
    const url = `${this.appUrl.soil}/save`;
    return this.apiService.getPostApiResponse(url, body);
  }
  async getSavedData(id) {
    const url = `${this.appUrl.soil}/saved/${id}`;
    console.log(url);
    return this.apiService.getGetApiResponse(url);
  }
}
