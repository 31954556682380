import React, { useState } from "react";
import useUpdateRequestsViewModel from "../UpdateRequests.screen/updateRequestViewModel";
import "./updateRequestsScreen.css";
import { useNavigate } from "react-router-dom";

const UpdateRequestsScreen = () => {
  const {
    allRequestsList,
    getAllRequests,
    isLoading,
    totalPages,
    currentPage,
    isViewModalOpen,
    selectedRequest,
    handleView,
    handleCloseViewModal,
  } = useUpdateRequestsViewModel();
  const navigation = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleViewReportButton = (testId) => {
    navigation(`/soilreport/${testId}`);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      getAllRequests(page);
    }
  };

  const filteredData = allRequestsList.filter((row) =>
    row?.request?.testId?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  return (
    <div className="container">
      <div className="header">
        <h1>अनुरोधों को अपडेट करें</h1>
        <input
          type="text"
          className="search-bar"
          placeholder="टेस्ट आईडी द्वारा खोजें"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {isLoading ? (
        <div className="loading-spinner">लोड हो रहा है...</div>
      ) : (
        <table className="data-table">
          <thead>
            <tr>
              <th>क्रम संख्या</th>
              <th>टेस्ट आईडी</th>
              <th>नाम</th>
              <th>रिपोर्ट</th>
              <th>डाउनलोड</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="5">कोई अनुरोध नहीं मिला!</td>
              </tr>
            ) : (
              filteredData.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.request.testId}</td>
                  <td>{row.request.name}</td>
                  <td>
                    <button
                      className="viewReport-button"
                      onClick={() => handleView(row)}
                    >
                      तत्व विवरण देखें
                    </button>
                  </td>
                  <td>
                    <button
                      className="download-button"
                      onClick={() => handleViewReportButton(row.request._id)}
                    >
                      रिपोर्ट देखें
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          पिछला
        </button>
        <span>
          {currentPage} में से {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          अगला
        </button>
      </div>
      {isViewModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseViewModal}>
              &times;
            </span>
            {selectedRequest ? (
              <table className="view-table">
                <thead>
                  <tr>
                    <th>तत्व</th>
                    <th>मूल्य</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "नाइट्रोजन",
                    "फॉस्फोरस",
                    "पोटैशियम",
                    "सल्फर",
                    "तांबा",
                    "जस्ता",
                    "मैंगनीज",
                    "लोहा",
                    "बोरान",
                    "जैविक कार्बन",
                    "चालकता",
                    "पीएच",
                    "यूरिया",
                    "डीएपी",
                    "एमओपी",
                    "जिंक सल्फेट",
                    "जिप्सम",
                    "बोरेक्स",
                    "फेरस सल्फेट",
                    "मैंगनीज सल्फेट",
                    "कॉपर सल्फेट",
                    "चूना",
                    "कैल्शियम सल्फेट",
                  ].map((element, idx) => (
                    <tr key={idx}>
                      <td>{element}</td>
                      <td>
                        {selectedRequest[element] !== undefined
                          ? parseFloat(selectedRequest[element]).toFixed(2)
                          : "एन/ए"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-data">कोई डेटा उपलब्ध नहीं है</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateRequestsScreen;
