export class AppUrl {
  constructor() {
    this.baseUrl = true
      ? "https://soiltesting.agrichikitsa.org/api/v1"
      : "http://localhost:9090/api/v1";
    this.authEndPoint = `${this.baseUrl}/auth`;
    this.fileUploadEndPoint = `${this.baseUrl}/upload`;
    this.userEndPoint = `${this.baseUrl}/users`;
    this.publicEndPoint = `${this.baseUrl}/public`;
    this.requests = `${this.baseUrl}/requests`;
    this.soil = `${this.baseUrl}/soil`;
  }
}
